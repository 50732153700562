import config from 'config/app'
import lib from 'lib/commons'

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  SPIN_ERROR,
  SPIN_SUCCESS,
  IS_SPINNING,

  SHARE_ERROR,
  SHARE_SUCCESS,
  IS_SHARING,

  CHECK_MATCH_ERROR,
  CHECK_MATCH_SUCCESS,
  IS_CHECKING_MATCH,

  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  IS_GETTING_HISTORY,
} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: {},
  currentTime: null,
  eventStarted: 0,
  roundConfigs: {},
  completeReward: {},

  isGettingHistory: false,
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        currentTime: payload.current_datetime,
        eventStarted: payload.event_started,
        roundConfigs: payload.round_configs,
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        currentTime: payload.current_datetime,
        eventStarted: payload.event_started,
        roundConfigs: payload.round_configs,
        errorGlobal: 'Không có thông tin user',
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

      //SPIN
      case SPIN_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            spin_tickets: payload.user.spin_tickets,
            round: payload.user.round
          },
          completeReward: payload?.complete_reward,
          roundConfigs: payload?.round_configs,
          isSpinning: false
        }
        break;
      case SPIN_ERROR:
        return {
          ...state,
          isSpinning: false
        }
        break;
      case IS_SPINNING:
        return {
          ...state,
          isSpinning: true
        }
        break;

    //SHARE
    case SHARE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          spin_tickets: payload.user.spin_tickets,
        },
        isSharing: false
      }
      break;
    case SHARE_ERROR:
      return {
        ...state,
        isSharing: false
      }
      break;
    case IS_SHARING:
      return {
        ...state,
        isSharing: true
      }
      break;

    //CHECK MATCH
    case CHECK_MATCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          spin_tickets: payload.user.spin_tickets,
        },
        isCheckingMatch: false
      }
      break;
    case CHECK_MATCH_ERROR:
      return {
        ...state,
        isCheckingMatch: false
      }
      break;
    case IS_CHECKING_MATCH:
      return {
        ...state,
        isCheckingMatch: true
      }
      break;

    //GET HISTORY
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: payload,
        isGettingHistory: false
      }
      break;
    case GET_HISTORY_ERROR:
      return {
        ...state,
        isGettingHistory: false
      }
      break;
    case IS_GETTING_HISTORY:
      return {
        ...state,
        isGettingHistory: true
      }
      break;

    default:
      return state
  }
}

export default {
  lng: "vi", //vi|en
  region: "VN", //vi|pk
  version: "1",
  domain: "manhghep.fconline.garena.vn",
  demo: 0,
  demoMsg: "Sự kiện sẽ bắt đầu vào ngày 05.10.2023",
  baseUrl: `https://manhghep.fconline.garena.vn`,
  shareHashtag: "ManhGhepT102023",
  shareQuote: "Nhanh tay ghép mảnh và nhận quà Free thôi nào!",
  predictGuide: "https://",
  spinGuide:
    "https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/tichdiem_eacc.png",
  prizeList:
    "https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/qua_ranking.png",
  prizeListGlobal:
    "https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2023/T10/PUZ/puz_qua.png",
  guideImg:
    "https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2023/T10/PUZ/puz_hd.png",
};

import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'
import lang from "lng/index"
const lng = lang[config.lng]

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const SPIN         = 'SPIN'
export const SPIN_ERROR   = 'SPIN_ERROR'
export const SPIN_SUCCESS = 'SPIN_SUCCESS'
export const IS_SPINNING  = 'IS_SPINNING'

export const SHARE         = 'SHARE'
export const SHARE_ERROR   = 'SHARE_ERROR'
export const SHARE_SUCCESS = 'SHARE_SUCCESS'
export const IS_SHARING    = 'IS_SHARING'

export const CHECK_MATCH         = 'CHECK_MATCH'
export const CHECK_MATCH_ERROR   = 'CHECK_MATCH_ERROR'
export const CHECK_MATCH_SUCCESS = 'CHECK_MATCH_SUCCESS'
export const IS_CHECKING_MATCH   = 'IS_CHECKING_MATCH'

export const GET_HISTORY         = 'GET_HISTORY'
export const GET_HISTORY_ERROR   = 'GET_HISTORY_ERROR'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const IS_GETTING_HISTORY  = 'IS_GETTING_HISTORY'

let tm

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if(response.error_code == 'no_account') {
          lib.showDownlad()
        }
        dispatch(getCurrentUserError(response))
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}
//Spin
export const isSpinning = (response) => {
  return {
    type: IS_SPINNING,
  }
}
export const spin = (setWinningItem, dataRound) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request('api/user/spin', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {

        let items = dataRound?.rewards || []
        let itemArray = []
        for (let index = 0; index < items.length; index++) {
          const item = items[index]
          if(!item?.received && item?.type == 'round'){
            itemArray.push(item?.position)
          }
        }

        let winItem = response.payload.reward

        let shuffledArray = lib.shuffleArray([...itemArray])
        let currentIndex = 0
        let turns = 0
        let maxTurn = itemArray.length == 1 ? 1 : 6

        tm = setInterval(() => {
          if(currentIndex == shuffledArray.length) {
            currentIndex = 0
          }
          if(turns >= maxTurn && shuffledArray[currentIndex] == winItem.position) {
            clearInterval(tm)
            $(`.spin__puzzle--${winItem.position}`).css('animation', 'zoomOut 2s ease 1 forwards')
            setTimeout(() => {
              lib.showMessage(`Bạn đã nhận được:<br><div class="item-cover"><img src="${winItem.image}" alt="" class="item-cover__img" /></div><p>${winItem.product_name}</p>`, null, 'Chúc mừng!', 'Xác nhận', 'popup-reward').then(res => {
                dispatch(spinSuccess(response))
              })
            }, 2500)
          }

          setWinningItem(shuffledArray[currentIndex])
          currentIndex++
          turns++
        }, 400)

      } else {
        lib.showError(response.error_code)
        dispatch(spinError(response))
      }
    })
  }
}
export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response.payload
  }
}
export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response.payload
  }
}
//Share
export const isSharing = (response) => {
  return {
    type: IS_SHARING,
  }
}
export const share = (shareCustom = null) => {
  return (dispatch, getState) => {
    dispatch(isSharing());
    request('api/user/share', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        if(shareCustom) {
          shareCustom()
        } else {
          lib.showMessage('<p class="text-center">Chia sẻ thành công!</p>')
        }
        dispatch(shareSuccess(response))
      } else {
        lib.showError(response.error_code)
        dispatch(shareError(response))
      }
    })
  }
}
export const shareSuccess = (response) => {
  return {
    type: SHARE_SUCCESS,
    payload: response.payload
  }
}
export const shareError = (response) => {
  return {
    type: SHARE_ERROR,
    payload: response.payload
  }
}

//Check match
export const isCheckingMatch = (response) => {
  return {
    type: IS_CHECKING_MATCH,
  }
}
export const checkMatch = () => {
  return (dispatch, getState) => {
    dispatch(isCheckingMatch());
    request('api/user/update-daily', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`
          <div class="row match-progress align-items-center">
            <div class="col-7 text-right">
              <p>Hoàn thành trận<span>XH/ GLXH</span></p>
            </div>
            <div class="col-5 text-left${response.payload.total_play == 1 ? ' active' : ''}">
              <img src="/images/play${response.payload.total_play}.png" alt="" class="play-progress" />
            </div>
            <div class="w-100"></div>
          </div>
        `, null, 'Tiến độ nhiệm vụ', null).then(res => {
          dispatch(checkMatchSuccess(response))
        })
      } else {
        lib.showError(response.error_code)
        dispatch(checkMatchError(response))
      }
    })
  }
}
export const checkMatchSuccess = (response) => {
  return {
    type: CHECK_MATCH_SUCCESS,
    payload: response.payload
  }
}
export const checkMatchError = (response) => {
  return {
    type: CHECK_MATCH_ERROR,
    payload: response
  }
}

//get history
export const isGettingHistory = () => {
  return {
    type: IS_GETTING_HISTORY
  }
}

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingHistory());
    request('api/user/history').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getHistorySuccess(response))
      } else {
        dispatch(getHistoryError(response))
      }
    })
  }
}

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  }
}

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload
  }
}
